import { getFirestore } from "firebase/firestore";
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDBLJYO0hKp0JtECWoBh24HeSUYEGT-vuA",
  authDomain: "crescendo-v1.firebaseapp.com",
  projectId: "crescendo-v1",
  storageBucket: "crescendo-v1.appspot.com",
  messagingSenderId: "200191467910",
  appId: "1:200191467910:web:30ea03e65c56cc64a10c07",
  measurementId: "G-B5YHE2JNPE"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

const database = getFirestore(app);

export { database };
export { analytics };