import React, { useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { collection, addDoc, serverTimestamp } from 'firebase/firestore';

import { useTheme } from '../ThemeContext';
import { database } from '../Firebase';

import Footer from './Footer'

import phone from '../assets/media/phonebkg.png';

import { SunIcon, MoonIcon } from '@heroicons/react/24/outline';

const RevealOnScroll = ({ children }) => {

    const [isVisible, setIsVisible] = useState(false);
    const ref = useRef(null);
  
    useEffect(() => {
      let observerRefValue = null;
      const scrollObserver = new IntersectionObserver(
        ([entry]) => {
          if (entry.isIntersecting) {
            setIsVisible(true);
            scrollObserver.unobserve(entry.target);
          }
        },
      );
  
      scrollObserver.observe(ref.current);
      observerRefValue = ref.current;
  
      return () => {
        if (observerRefValue) {
          scrollObserver.unobserve(observerRefValue);
        }
      };
    }, []);
    
    const classes = `transition-opacity duration-1500 ${
      isVisible ? 'opacity-100' : 'opacity-0'
    }`;
  
    return (
      <div ref={ref} className={classes}>
        {children}
      </div>
    );
  };

const Home = () => {
    const [email, setEmail] = useState("");
    const emailInputRef = useRef(null);
    const [submitStatus, setSubmitStatus] = useState(null);
    const [invalidEmailAlert, setInvalidEmailAlert] = useState(false);
    const isEmailValid = (email) => {
        // Regular expression for a simple email validation
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };
    
    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!isEmailValid(email)) {
            setInvalidEmailAlert(true);
            setTimeout(() => setInvalidEmailAlert(false), 2500);
            return;
        }
      
        try {
          await addDoc(collection(database, "waitlist"), { email: email, created_time: serverTimestamp() });
          setSubmitStatus("success");
          setTimeout(() => emailInputRef.current.value = "", 500);
          setTimeout(() => setSubmitStatus(null), 3000);
        } catch (error) {
          console.error("Error adding document: ", error);
          setSubmitStatus("failure");
        }
    };

    const location = useLocation();

    const { isDarkMode, toggleTheme } = useTheme();

    useEffect(() => {
        // Check if the location has an anchor (e.g., /about#mailing)
        if (location.hash) {
        const targetElement = document.getElementById(location.hash.substring(1));
        if (targetElement) {
            // Scroll to the target element with smooth behavior
            targetElement.scrollIntoView({ behavior: 'smooth', block: "start"});
        }
        }
    }, [location]);

    return (
        <div className={isDarkMode ? 'min-h-screen bg-slate-800 text-slate-100' : 'min-h-screen bg-slate-100 text-slate-800'}>

            {/* Header */}
            <header 
                className="min-h-screen flex items-center justify-center transition-all duration-500 ease-in-out"
            >   
                <RevealOnScroll>
                    <div className="flex flex-col items-center mx-auto relative z-10">
                        <div className="text-center md:text-8xl text-5xl font-semibold">
                            <h1 className="bg-gradient-to-r from-[#415FFF] to-[#322C99] inline-block text-transparent bg-clip-text">Crescendo</h1>
                        </div>
                        <div className="text-center mt-2 mx-4 text-2xl md:text-4xl font-medium">
                            <p className="">Free, bite-sized AI-powered SAT and ACT test prep. Coming soon to iOS and Android.</p>
                        </div>
                        <div className="p-8 m-8 md:w-1/2 shadow-[0.625rem_0.625rem_0.875rem_0_rgb(225,226,228),-0.5rem_-0.5rem_1.125rem_0_rgb(255,255,255)] rounded-lg">
                            <form onSubmit={handleSubmit}>
                                <label htmlFor="email" className="block text-lg font-medium">
                                    Email
                                </label>
                                <div id="mailing" className="mt-1 flex flex-col items-center rounded-md">
                                    <input
                                    ref={emailInputRef}
                                    type="email"
                                    id="email"
                                    name="email"
                                    maxLength={60}
                                    onChange={(e) => setEmail(e.target.value)}
                                    className="text-slate-800 focus:ring-indigo-500 focus:border-indigo-500 w-full p-1 rounded-md text-2xl md:text-3xl border-gray-300"
                                    placeholder="you@email.com"
                                    />
                                    <button
                                        type="submit"
                                        className={`mt-3 inline-flex items-center px-4 py-2 rounded-lg text-xl font-light text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 ${
                                        submitStatus === "success" ? "bg-green-500" : "bg-[#415FFF] hover:bg-[#322C99]"
                                        }`}
                                    >
                                    Join the Waitlist
                                    </button>
                                </div>
                            </form>
                            {/* Custom invalid email alert */}
                            {invalidEmailAlert && (
                                <div className="absolute top-0 left-0 right-0 bg-red-500 text-white text-center p-2">
                                Invalid email address. Please try again.
                                </div>
                            )}
                        </div>
                    </div>
                </RevealOnScroll>
                {/* Scroll Down Arrow */}
                <div className="absolute bottom-10 animate-bounce cursor-pointer">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-8 h-8">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 13.5 12 21m0 0-7.5-7.5M12 21V3" />
                    </svg>
                </div>
            </header>

            {/* Main Content */}
            <main className="container mx-auto p-10 transition-all duration-500 ease-in-out ">

                {/* ABOUT US */}
                <div className="md:flex md:flex-row">
                    <div className=" my-8 md:w-1/3">
                        <RevealOnScroll>
                            <img className="object-cover" src={phone} alt="Phone" />
                        </RevealOnScroll>
                    </div>
                    <div className="flex flex-col justify-center md:w-2/3 my-4 md:p-8">
                    <div className="md:px-10">
                        <RevealOnScroll>
                            <h1 className="text-center font-bold md:text-6xl text-4xl py-2">Built by students, for students.</h1>
                        </RevealOnScroll>
                        <RevealOnScroll>
                            <p className="text-left lg:text-3xl md:text-2xl text-xl mt-4">
                                The world moves quickly. 
                                Crescendo enables you to study SAT and ACT questions while traveling, commuting, waiting for a ranked queue, resting between gym sets, or during any downtime you may have.
                            </p>
                        </RevealOnScroll>
                        <RevealOnScroll>
                            <p className="text-left lg:text-3xl md:text-2xl text-xl mt-4">
                                Crescendo facilitates nonstop practice and learning, anytime and anywhere, combined with powerful statistics to provide students the best opportunities for improvement.
                            </p>
                        </RevealOnScroll>
                    </div>
                </div>
                </div>
                

                <RevealOnScroll>
                    {/* Neumorphic Design */}
                    <div className="mt-10 p-10 shadow-[0.625rem_0.625rem_0.875rem_0_rgb(225,226,228),-0.5rem_-0.5rem_1.125rem_0_rgb(255,255,255)] rounded-lg flex flex-wrap justify-center gap-5 [&>*]:w-[8rem] [&>*]:h-[8rem] [&>*]:py-4 [&>*:hover]:shadow-[0.625rem_0.625rem_0.875rem_0_rgb(225,226,228),-0.5rem_-0.5rem_1.125rem_0_rgb(255,255,255)] 
                    [&>*>div]:w-16 [&>*>div]:h-16 [&>*>div]:rounded-full [&>*>div]:text-white [&>*>div]:flex [&>*>div]:items-center [&>*>div]:justify-center [&>*>div]:mx-auto [&>*>h2]:text-[0.8rem] [&>*>h2]:mt-4 [&>*>h2]:text-center">
                        <a target="_blank" rel="noreferrer" href="https://www.instagram.com/crescendo.app/">
                            <div className="bg-gradient-to-tr from-[#FFD600] via-[#FF0069] to-[#7638FA]">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" className="w-8 h-8" viewBox="0 0 16 16">
                                    <path d="M8 0C5.829 0 5.556.01 4.703.048 3.85.088 3.269.222 2.76.42a3.917 3.917 0 0 0-1.417.923A3.927 3.927 0 0 0 .42 2.76C.222 3.268.087 3.85.048 4.7.01 5.555 0 5.827 0 8.001c0 2.172.01 2.444.048 3.297.04.852.174 1.433.372 1.942.205.526.478.972.923 1.417.444.445.89.719 1.416.923.51.198 1.09.333 1.942.372C5.555 15.99 5.827 16 8 16s2.444-.01 3.298-.048c.851-.04 1.434-.174 1.943-.372a3.916 3.916 0 0 0 1.416-.923c.445-.445.718-.891.923-1.417.197-.509.332-1.09.372-1.942C15.99 10.445 16 10.173 16 8s-.01-2.445-.048-3.299c-.04-.851-.175-1.433-.372-1.941a3.926 3.926 0 0 0-.923-1.417A3.911 3.911 0 0 0 13.24.42c-.51-.198-1.092-.333-1.943-.372C10.443.01 10.172 0 7.998 0h.003zm-.717 1.442h.718c2.136 0 2.389.007 3.232.046.78.035 1.204.166 1.486.275.373.145.64.319.92.599.28.28.453.546.598.92.11.281.24.705.275 1.485.039.843.047 1.096.047 3.231s-.008 2.389-.047 3.232c-.035.78-.166 1.203-.275 1.485a2.47 2.47 0 0 1-.599.919c-.28.28-.546.453-.92.598-.28.11-.704.24-1.485.276-.843.038-1.096.047-3.232.047s-2.39-.009-3.233-.047c-.78-.036-1.203-.166-1.485-.276a2.478 2.478 0 0 1-.92-.598 2.48 2.48 0 0 1-.6-.92c-.109-.281-.24-.705-.275-1.485-.038-.843-.046-1.096-.046-3.233 0-2.136.008-2.388.046-3.231.036-.78.166-1.204.276-1.486.145-.373.319-.64.599-.92.28-.28.546-.453.92-.598.282-.11.705-.24 1.485-.276.738-.034 1.024-.044 2.515-.045v.002zm4.988 1.328a.96.96 0 1 0 0 1.92.96.96 0 0 0 0-1.92zm-4.27 1.122a4.109 4.109 0 1 0 0 8.217 4.109 4.109 0 0 0 0-8.217zm0 1.441a2.667 2.667 0 1 1 0 5.334 2.667 2.667 0 0 1 0-5.334"/>
                                </svg>
                            </div>
                            <h2 className="text-center text-2xl font-semibold">INSTAGRAM</h2>
                        </a>
                        <a href="/#mailing">
                            <div className="bg-blue-500 hover:bg-blue-600">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M3.75 12h16.5m-16.5 3.75h16.5M3.75 19.5h16.5M5.625 4.5h12.75a1.875 1.875 0 0 1 0 3.75H5.625a1.875 1.875 0 0 1 0-3.75Z" />
                            </svg>

                            </div>
                            <h2 className="text-center text-2xl font-semibold">WAITLIST</h2>
                        </a>
                        <a target="_blank" rel="noreferrer" href="https://discord.gg/rv6eKGJPyH">
                            <div className="bg-[#5865F2]">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" className="w-8 h-8" viewBox="0 0 16 16">
                                <path d="M13.545 2.907a13.227 13.227 0 0 0-3.257-1.011.05.05 0 0 0-.052.025c-.141.25-.297.577-.406.833a12.19 12.19 0 0 0-3.658 0 8.258 8.258 0 0 0-.412-.833.051.051 0 0 0-.052-.025c-1.125.194-2.22.534-3.257 1.011a.041.041 0 0 0-.021.018C.356 6.024-.213 9.047.066 12.032c.001.014.01.028.021.037a13.276 13.276 0 0 0 3.995 2.02.05.05 0 0 0 .056-.019c.308-.42.582-.863.818-1.329a.05.05 0 0 0-.01-.059.051.051 0 0 0-.018-.011 8.875 8.875 0 0 1-1.248-.595.05.05 0 0 1-.02-.066.051.051 0 0 1 .015-.019c.084-.063.168-.129.248-.195a.05.05 0 0 1 .051-.007c2.619 1.196 5.454 1.196 8.041 0a.052.052 0 0 1 .053.007c.08.066.164.132.248.195a.051.051 0 0 1-.004.085 8.254 8.254 0 0 1-1.249.594.05.05 0 0 0-.03.03.052.052 0 0 0 .003.041c.24.465.515.909.817 1.329a.05.05 0 0 0 .056.019 13.235 13.235 0 0 0 4.001-2.02.049.049 0 0 0 .021-.037c.334-3.451-.559-6.449-2.366-9.106a.034.034 0 0 0-.02-.019Zm-8.198 7.307c-.789 0-1.438-.724-1.438-1.612 0-.889.637-1.613 1.438-1.613.807 0 1.45.73 1.438 1.613 0 .888-.637 1.612-1.438 1.612m5.316 0c-.788 0-1.438-.724-1.438-1.612 0-.889.637-1.613 1.438-1.613.807 0 1.451.73 1.438 1.613 0 .888-.631 1.612-1.438 1.612"/>
                                </svg>
                            </div>
                            <h2 className="text-center text-2xl font-semibold">DISCORD</h2>
                        </a>
                    </div>
                </RevealOnScroll>

            </main>
            <Footer />
            <button onClick={toggleTheme} className="md:text-xl fixed top-4 right-4 p-2">
                {isDarkMode ? <SunIcon className="h-10 w-10" /> : <MoonIcon className="h-10 w-10" />}
            </button>
        </div>
    );
};

export default Home;
