import React from 'react'

const Footer = () => {
  return (
    <footer className="bg-slate-100 mt-10 p-2 shadow-[0.625rem_0.625rem_0.875rem_0_rgb(225,226,228),-0.5rem_-0.5rem_1.125rem_0_rgb(255,255,255)]">
        <div className="container mx-auto text-center text-slate-800">
            <b>Email:</b> admin@crescendo.study | <b>Instagram:</b> @crescendo.app | <b>Crescendo Platforms, LLC</b>
        </div>
    </footer>
  )
}

export default Footer